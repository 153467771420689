export const geoApiOptions = {
  method: "GET",
  headers: {
    "X-RapidAPI-Key": "1e7527d9abmsh0287706c55ed18ep186f8ejsn2cbe65205a75",// enter your rapid api key here
    "X-RapidAPI-Host": "wft-geo-db.p.rapidapi.com",
  },
};
export const GEO_API_URL = "https://wft-geo-db.p.rapidapi.com/v1/geo";

export const WEATHER_API_URL = "https://api.openweathermap.org/data/2.5";
export const WEATHER_API_KEY = "24d303663798e94fe45844fcddaff801"; // enter your key from openweather API

export const POLLUTION_API_URL = "https://api.waqi.info/feed";
export const POLLUTION_API_KEY = "10d484153d4510c37ee1d19e67cfae7fd77e0c95"; // enter your key from waqi API